import React from "react";
import { Button, Switch } from "antd";

const IconFont = Loader.loadBaseComponent("IconFont");
const AuthComponent = Loader.loadBusinessComponent("AuthComponent");

class PictureSearch extends React.Component {

  setVisible = (showDefaultRect) => {
    const { changeSelectStatus, changeRectStatus } = this.props;
    if(showDefaultRect) {
      // 取消识图
      changeSelectStatus(false);
    }
    changeRectStatus(showDefaultRect)
  }
  /**
   * @desc 根据条件渲染图库图标
   */
  renderItem = (isOpenSelect) => {
    const { data, type, handleLink } = this.props;
    if (!data) {
      return null;
    }
    const FaceIcon = (
      <AuthComponent actionName="faceLibrary">
        <Button onClick={() => handleLink("face")}>
          <IconFont type="icon-S_Bar_Face" />
          {!!data.hasFace ? "关联人脸检索" : "人脸检索"}
        </Button>
      </AuthComponent>
    );
    const BodyIcon = (
      <AuthComponent actionName="bodyLibrary">
        <Button onClick={() => handleLink("body")}>
          <IconFont type="icon-M_AID_Body" />
          {!!data.hasBody ? "关联人体检索" : "人体检索"}
        </Button>
      </AuthComponent>
    );
    const verhicleIcon = (
      <AuthComponent actionName="vehicleLibrary">
        <Button onClick={() => handleLink("vehicle")}>
          <IconFont type="icon-S_Bar_Motor" />
          车辆检索
        </Button>
      </AuthComponent>
    );
    const nonVerhicleIcon = (
      <AuthComponent actionName="nonVehicleLibrary">
        <Button onClick={() => handleLink("nonVehicle")}>
          <IconFont type="icon-S_Bar_NonMotor" />
          车辆检索
        </Button>
      </AuthComponent>
    );
    const PersonIcon = (
      <AuthComponent actionName="objectMapPersonnel">
        <Button onClick={() => handleLink('person')}>
          <IconFont type="icon-S_Bar_NameCard" />
          人员档案
        </Button>
      </AuthComponent>
    );
    let templete = [];
    switch (type) {
      case "face":
        templete.push(FaceIcon); // 人脸检索
        !!data.hasBody && templete.push(BodyIcon); // 关联人体检索
        break;
      case "body":
        templete.push(BodyIcon); // 人体检索
        !!data.hasFace && templete.push(FaceIcon); // 关联人脸检索
        break;
      case "vehicle":
        templete.push(verhicleIcon);
        break;
      case "nonVehicle":
        templete.push(nonVerhicleIcon);
        break;
      default:
        // templete.push(FaceIcon);
        break;
    }
    if (data.aid && data.aid !== '0' && data.personInfoUrl) {
      templete.push(PersonIcon);
    }
    // 仅支持门禁记录 可能存在personId, 没有aid的情况
    if(type === 'accessControl' && data.personId){
      templete.push(PersonIcon);
    }
    return templete ? templete : null;
  };

  render() {
    const { isOpenSelect, changeSelectStatus, rectBtn=true, showDefaultRect, hasAllRect } = this.props;
    if(!rectBtn) {
      return (
        <div className="picture-search">
          <Button onClick={() => changeSelectStatus(!isOpenSelect)}>
            <IconFont type="icon-S_View_SearchBox" />
            {isOpenSelect ? "取消框选" : "框选搜图"}
          </Button>
        </div>
      )
    }
    return (
      <div className="picture-search">
        <Button onClick={() => changeSelectStatus(!isOpenSelect)}>
          <IconFont type="icon-S_View_SearchBox" />
          {isOpenSelect ? "取消框选" : "框选搜图"}
        </Button>
        {this.renderItem(isOpenSelect)}
        { hasAllRect && (
          <span className='rect-switch'>
            <Switch
              className=''
              checked={!showDefaultRect}
              disabled={isOpenSelect}
              size='small'
              onChange={() => this.setVisible(!showDefaultRect)}
            />
            <span>
              {showDefaultRect ? '智能识图' : '取消识图'}
            </span>
          </span>

        )}
      </div>
    );
  }
}
export default PictureSearch;
