import React from 'react';
import PictureTools from './components/PictureTools';
import PictureSearch from './components/PictureSearch';
import moment from 'moment';
import {message} from 'antd'
import './index.scss';

const PictureView = Loader.loadBaseComponent("PictureView");

const faceTypeName = ['sex', 'generation', 'hat', 'head', 'eyeGlass', 'faceMask', 'upperColor', 'upperTexture', 'sunglasses'];

const bodyTypeName = ['sex', 'generation', 'hat', 'head', 'upperTexture', 'lowerTexture', 'goods', 'eyeGlass', 'faceMask', 'lowerBodyClothing', 'upperColor', 'lowerColor', 'sunglasses'];

const vehicleTypeName = ["plateColor", "vehicleBrands", "vehicleType", "vehicleColor"];

const nonVehicleTypeName = ["sex", "nonMotorVehicleModel", "vehicleColor", "head", "upperColorType", "upperColor"];

const Rect_Type = {
  face: "faceLibrary",
  body: "bodyLibrary",
  vehicle: "vehicleLibrary",
  nonVehicle: "nonVehicleLibrary"
};

class PictureCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.domRef = React.createRef();
    this.timer = null;
    this.state = {
      canRender: false,
      rects: props.rects || [],
      showDefaultRect: true
    };
  }

  getTypeItem = (tag) => {
    let typeCode = tag.substring(0, 5) + "0";
    let typeItem = Dict.typeCode.find(x => x.code == typeCode);
    if (!typeItem) {
      typeCode = tag.substring(0, 4) + "00";
      typeItem = Dict.typeCode.find(x => x.code == typeCode);
    }
    if (!typeItem) {
      return { typeItem: false };
    }
    // typeCodes.push(typeCode);
    const tagLabel = Dict.getLabel(typeItem.name, tag, true);
    return { typeItem, tagLabel };
  };

  newTags = (tags, typeItem, tagLabel) => {
    tags.push({
      tagLabel,
      typeLabel: typeItem.label,
      typeIcon: typeItem.icon
    });
  };

  async componentDidMount() {
    const { imgId } = this.props.data;
    if (!imgId) {
      this.setState({ canRender: true });
      return false;
    }
    const res = await Service.face.queryCaptureAllTargetByImgId(imgId);
    if (!res) {
      this.setState({ canRender: true });
      return false;
    }
    let rects = [];
    const { persons, vehicles } = res.data;
    if (persons && persons.length) {
      persons.map(v => {
        let tags = [],
          faceTags = [],
          bodyTags = [];
          // typeCodes = [];
        if (v.personTags) {
          tags = v.personTags.filter(tag => tag.length === 6);
          tags = new Set([...tags]);
          tags.forEach(tag => {
            let { typeItem, tagLabel } = this.getTypeItem(tag);
            if (!typeItem) {
              return;
            }
            if (faceTypeName.includes(typeItem.name)) {
              this.newTags(faceTags, typeItem, tagLabel);
            }
            if (bodyTypeName.includes(typeItem.name)) {
              this.newTags(bodyTags, typeItem, tagLabel);
            }
          });
        }
        if (v.faceRect && v.hasFace) {
          rects.push({
            type: "face",
            value: v.faceRect,
            url: v.faceUrl,
            feature: v.faceFeature,
            tags: faceTags,
            smId: v.id
          });
        }
        if (v.bodyRect && v.hasBody) {
          rects.push({
            type: "body",
            value: v.bodyRect,
            url: v.bodyUrl,
            feature: v.bodyFeature,
            tags: bodyTags,
            smId: v.id
          });
        }
      });
    }
    if (vehicles && vehicles.length) {
      vehicles.map(v => {
        let tags = [],
          vehicleTags = [],
          nonVehicleTags = [];
          // typeCodes = [];
        if (v.vehicleTags) {
          tags = v.vehicleTags.filter(tag => tag.length === 6);
          tags = new Set([...tags]);
          tags.forEach(tag => {
            let { typeItem, tagLabel } = this.getTypeItem(tag);
            if (!typeItem) {
              return;
            }
            if (v.vehicleType == "0" && vehicleTypeName.includes(typeItem.name)) {
              this.newTags(vehicleTags, typeItem, tagLabel);
            }
            if (v.vehicleType == "1" && nonVehicleTypeName.includes(typeItem.name)) {
              this.newTags(nonVehicleTags, typeItem, tagLabel);
            }
          });
        }
        let type = v.vehicleType == "0" ? "vehicle" : "nonVehicle";
        rects.push({
          type,
          value: v.vehicleRect,
          url: v.vehicleUrl,
          feature: v.feature,
          tags: v.vehicleType == "0" ? vehicleTags : nonVehicleTags,
          smId: v.id
        });
      });
    }
    const rect = this.props.rects && this.props.rects[0]?this.props.rects[0]:{}
    const { type, value } = rect;
    const rectItem = rects.filter(v => v.type === type).find(v => v.value === value);
    if (rectItem) {
      rectItem.default = true;
    }
    this.setState({
      rects,
      canRender: true
    });
  }

  componentWillUnmount() {
    this.domRef = null;
    clearTimeout(this.timer);
    this.timer = null;
  }

  imgDownload = () => {
    const { imagePath, downloadName = `下载图片_${moment(Date.now()).format("YYYYMMDDTHHmmss")}` } = this.props;
    Utils.downloadLocalImage(imagePath, downloadName);
  };

  fullScreenChange = reloadPicture => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      reloadPicture();
    }, 200);
  };

  // 框选搜图事件
  goPage = ({ name, imageUrl, searchType = "1", feature = "", defaultItem = {}, isSelect = false }) => {
    const { beforeJumppage, captureTime = Date.now(), imagePath, data } = this.props;
    const { rects } = this.state;
    if (Utils.isFullscreen(this.domRef.current)) {
      Utils.exitFullscreen();
    }
    const searchData = {
      startTime: moment(captureTime).subtract(72, "h") * 1,
      endTime: moment(captureTime).add(72, "h") * 1,
      timerTabsActive: 2
    };
    let id = defaultItem.smId ? `${defaultItem.smId}-${defaultItem.value}` : Utils.uuid();
    searchData.endTime = searchData.endTime > moment().valueOf() ? moment().valueOf() : searchData.endTime;
    LM_DB.add("parameter", {
      id,
      url: imageUrl,
      searchData,
      rects: !isSelect ? rects : undefined,
      feature,
      imgId: !isSelect ? data.imgId : undefined,
      frameUrl: !isSelect ? imagePath : imageUrl,
      smId: defaultItem.smId,
      defaultRect: defaultItem
    }).then(() => {
      beforeJumppage && beforeJumppage();
      BaseStore.tab.goPage({
        moduleName: name,
        data: { id, isSearch: true, searchType }
      });
    });
  };

  // 关联搜索
  handleLink = linkType => {
    const { imagePath, data, captureTime,type ,beforeJumppage} = this.props;
    let indexDBSearchData = {};
    if (linkType === "person") {
      return this.HandlePeopleLink();
    } else {
      indexDBSearchData = {
        startTime: moment(captureTime).subtract(72, "h") * 1,
        endTime: moment(captureTime).add(72, "h") * 1,
        timerTabsActive: 2
      };
    }
    LM_DB.add("parameter", {
      id: data.captureId || data.id,
      url: data[`${type}Url`],
      frameUrl: imagePath,
      imgId:data.imgId,
      smId:data.id,
      data,
      searchData: indexDBSearchData
    }).then(() => {
      beforeJumppage && beforeJumppage();
      BaseStore.tab.goPage({
        moduleName: `${linkType}Library`,
        data: { id: data.captureId || data.id, isSearch: true, searchType: 0 }
      });
    });
  };

  // 跳转人员档案 -- 有问题找晨星哥哥
  HandlePeopleLink = async () => {
    const { data ,beforeJumppage} = this.props;
    const aid = data.aid;
    let type = aid ? 0 : 1;
    let options = { aid, personId: !aid ? data.personId : "" };
    let personId;
    let aids = [];
    let hasAid;
    let id;
    let presonType;
    try {
      const result = await Service.person.queryPersonBindInfo(options);
      personId = result.data.personId;
      aids = result.data.aids || [];
      hasAid = !!result.data.hasAid;
    } catch (e) {
      console.error(e);
      return message.warn("获取人员ID失败");
    }

    if (type === 0 && personId) {
      id = personId;
      presonType = "ploy";
    }
    if (type === 0 && !personId && aids.length === 0) {
      id = aid;
      presonType = "aid";
    }

    if (type === 1) {
      id = data.personId;
      hasAid ? (presonType = "ploy") : (presonType = "entry");
    }

    BaseStore.tab.openDetailPage({
      moduleName: "objectMapPersonnelDetail",
      value: id,
      data: { id },
      beforeAction: async () => {
        beforeJumppage && beforeJumppage()
        await window.LM_DB.add("parameter", { id, url: data.personInfoUrl, type: presonType });
      }
    });
  };

  // 默认结构化框点击事件
  onClickDefaultRect = (item, rectUrl) => {
    if (item.feature) {
      this.goPage({
        name: Rect_Type[item.type],
        imageUrl: item.url,
        searchType: "2",
        feature: item.feature,
        defaultItem: item
      });
    } else {
      this.goPage({
        name: Rect_Type[item.type],
        imageUrl: rectUrl,
        searchType: "1",
        defaultItem: item
      });
      // message.info('没有提取到结构化特征')
    }
  };

  changeRectStatus = showDefaultRect => {
    this.setState({
      showDefaultRect
    });
  };

  renderOptions = (
    { setScale, setRotate, resetPicture, changeSelectStatus, isOpenSelect, reload },
    { containerEle, ...searchOptions }
  ) => {
    const { showDefaultRect } = this.state;
    const { imgId } = this.props.data;
    return (
      <>
        <PictureTools
          fullScreenChange={() => this.fullScreenChange(reload)}
          imgDownload={this.imgDownload}
          setScale={setScale}
          setRotate={setRotate}
          resetPicture={resetPicture}
          containerEle={containerEle}
        />
        <PictureSearch
          hasAllRect={imgId}
          changeRectStatus={this.changeRectStatus}
          handleLink={this.handleLink}
          {...searchOptions}
          showDefaultRect={showDefaultRect}
          isOpenSelect={isOpenSelect}
          changeSelectStatus={changeSelectStatus}
        />
      </>
    );
  };
  render() {
    const {
      className = "",
      imagePath,
      rectMenuVisible = true,
      showRectInfo = true,
      showRect = true,
      ...searchOptions
    } = this.props;
    const { rects, canRender, showDefaultRect } = this.state;
    if (!canRender) {
      return null;
    }
    
    return (
      <div className={`picture-canvas-layout ${className}`} ref={this.domRef}>
        <PictureView
          imagePath={imagePath}
          rects={rects}
          onClickDefaultRect={this.onClickDefaultRect}
          goPage={this.goPage}
          rectMenuVisible={rectMenuVisible}
          showRectInfo={showRectInfo}
          showDefaultRect={showDefaultRect}
        >
          {options => this.renderOptions(options, { containerEle: this.domRef.current, ...searchOptions })}
        </PictureView>
      </div>
    );
  }
}

export default PictureCanvas;
